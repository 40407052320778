<template>
  <div class="container--fluid fill-height">
    <v-container>
      <TheLayoutPageTitle :title="$t('route.portal.atendimento.title')" />
      <iframe
        src="https://servicos.decea.gov.br/sac/index.cfm?a=aga"
        width="100%"
        height="1396px"
        frameborder="0"
        webkitAllowFullScreen
        mozallowfullscreen
        allowFullScreen
      />
    </v-container>
  </div>
</template>

<script>
import TheLayoutPageTitle from '../layout/TheLayoutPageTitle.vue';

export default {
  components: { TheLayoutPageTitle },
};
</script>

<template>
  <div class="d-flex justify-center my-5">
    <h1 class="grey--text">
      {{ title }}
    </h1>
  </div>
</template>

<script>
export default {
  name: 'TheLayoutPageTitle',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
  .grey--text {
    color: #646E82 !important;
  }
</style>
